import { MutationTree, Mutation } from 'vuex';
import { IMenuItem, IState } from './state';
import { ActionPayload, IAddMenuItemsPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const addMenuItemsMutation: MutationHandler<IAddMenuItemsPayload> = (state, { payload }) => {
	console.log('mutating menuoitems')
	if(payload.menuItem) {
		Object.assign(payload.menuItem, { children: payload.items})
	}
}
const toggleExpandedMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded})
}
const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	addMenuItems: addMenuItemsMutation,
	toggleExpanded: toggleExpandedMutation
}

export default mutations
