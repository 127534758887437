
import Vue from 'vue'
import Menu from './components/Menu.vue'

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	components: {
		Menu
	}
})
