
import Vue from 'vue'
import { ITopLinks } from '../store/state'

interface TopLinksMethods {
}

interface TopLinksComputed {
    topLinks: ITopLinks
}

interface TopLinksProps {	
}

export default Vue.extend<{}, TopLinksMethods, TopLinksComputed, TopLinksProps>({
    name: 'TopLinks', 
    computed: {
        topLinks() {
            return this.$store.getters.topLinks;
        }
	},
	components: {
		
	}
})
