
import Vue from 'vue'
import { IExtraLink, IShareLink } from '../store/state'

interface ExtraLinksMethods {
    toggleShare(): void
}

interface ExtraLinksComputed {
    extraLinks: string
    shareLinks: IShareLink[]
}

interface ExtraLinksProps {	
}
interface ExtraLinksData {
    showShare: boolean
}

export default Vue.extend<ExtraLinksData, ExtraLinksMethods, ExtraLinksComputed, ExtraLinksProps>({
    name: 'ExtraLinks',
    data() {
        return {
            showShare: false
        }
    },
    computed: {
        extraLinks() {
            return this.$store.getters.extraLinks;
        },
        shareLinks() {
            return this.$store.getters.shareLinks;
        }
	},
    methods: {
        toggleShare() {
            this.showShare = !this.showShare;
        }
    },
	components: {
		
	}
})
