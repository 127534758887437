
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { toggleExpanded } from '../store/actions'
import { ITranslations } from '../store/state'
interface MenuItemMethods {
    toggleClick(): void
}

interface MenuItemComputed {
	translations: ITranslations
    levelClass: any
    className: string
    iconClassName: string
}

interface MenuItemProps {
    item: IMenuItem
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>({
    name: 'MenuItem',
    data() {
        return {
            iconsObject: {
                'Barn och utbildning': 'icon-children',
                'Omsorg, stöd och hjälp': 'icon-heart',
                'Uppleva och göra': 'icon-masks',
                'Bygga, bo och miljö': 'icon-house',
                'Trafik och gator': 'icon-car',
                'Näringsliv och arbete': 'icon-briefcase',
                'Kommun och politik': 'icon-handshake',
            }
        }
    },
    props: {
		item: {},
	},
    methods: {
        toggleClick() {
            this.$store.dispatch(toggleExpanded(this.$props.item))
        }
    }, 
    computed: {
        translations() {
			return this.$store.getters.translations
		},
        levelClass() {
            return 'level-' + this.$props.item.level;
        },
        className() {
            return this.$store.getters.className;
        }, 
        iconClassName() {
            let iconsObject = this.$data.iconsObject;
            let iconClassName = iconsObject[this.item.name];
            return iconClassName || '';

        }
    },
	components: {
		
	}
})
