import { Getter } from "vuex";
import state, { IState } from './state';

const getChildrenUrl: Getter<IState, IState> = (state) => state.apiURLs.getChildren;
const menuItems: Getter<IState, IState> = (state) => state.menuItems;
const secondMenuItems: Getter<IState, IState> = (state) => state.secondMenuItems;
const translations: Getter<IState, IState> = (state) => state.translations;
const extraLinks: Getter<IState, IState> = (state) => state.extraLinks;
const className: Getter<IState, IState> = (state) => state.className;
const topLinks: Getter<IState, IState> = (state) => state.topLinks;
const campaignWeb: Getter<IState, IState> = (state) => state.campaignWeb;
const shareLinks: Getter<IState, IState> = (state) => state.shareLinks;

export default {
	getChildrenUrl,
	menuItems,
	secondMenuItems,
	translations,
	extraLinks,
	className,
	topLinks,
	campaignWeb,
	shareLinks
}
